import http from '../Mixin/http.mixin'

export const ssoStore = {
  state: {

  },
  mutations: {

  },
  actions: {
    sso_connections: ({commit}, data) => {
      return new Promise((resolve, reject) => {
        let url = '/sso_connections?baseSiteUri=' + process.env.VUE_APP_DOMAIN.replace('m.' , '') + '/user/sso-mobile&redirectUri=login';
        if (data && data.eec) {
          url = '/sso_connections?baseSiteUri=' + process.env.VUE_APP_DOMAIN.replace('m.' , '') + '/user/sso-mobile&redirectUri=eec-incentive';
        }
         http.get(url)
          .then(resp => {
            resolve(resp.data['hydra:member'])
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    my_cee_subscriptions: ({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/my_cee_subscriptions';
        http.get(url)
          .then(resp => {
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    my_eec_eligibility:({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/my_eec_eligibility';
        http.get(url)
          .then(resp => {
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    eec_instance:({commit}, data) => {
  return new Promise((resolve, reject) => {
    const url = '/eec/instance';
    http.get(url)
      .then(resp => {
        resolve(resp.data)
      })
      .catch(err => {
        reject(err)
      })
  })
},
    login_sso: ({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/login-sso';
        http.post(url, data)
          .then(resp => {
            resolve(resp.data['hydra:member'])
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    patch_user: ({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/users/' + data.userId + '/updateSso';
        http.patch(url, data.body, { headers: {'Content-Type' : `application/merge-patch+json`}})
          .then(resp => {
            commit('patch_user_sso', resp.data);
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    incentives:({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/incentives';
        http.get(url)
          .then(resp => {
            resolve(resp.data['hydra:member'])
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    incentive:({commit}, data) => {
      return new Promise((resolve, reject) => {
        const url = '/incentives?incentive_id=' + data;
        http.get(url)
          .then(resp => {
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {

  }
}

export default { ssoStore };
