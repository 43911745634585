import http from '../Mixin/http.mixin'

export const relaypointStore = {
  state: {

  },
  mutations: {

  },
  actions: {
    relay_points: ({commit}, data) => {
      return new Promise((resolve, reject) => {
        http.get(`relay_points/public`, {params: data})
          .then(resp => {
            resolve(resp.data['hydra:member'])
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {

  }
}

export default { relaypointStore };
