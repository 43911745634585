<template>
  <ion-page>
    <ion-header no-border>
      <ion-toolbar color="primary">
        <ion-buttons slot="start"></ion-buttons>
        <ion-title text-center>{{ $t('solidaryTransport.login.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content color="primary" class="is-scrollable">
      <div class="mc-st-container">
        <div class="mc-st-form">

          <div class="mc-st-form-content">
            <ion-item class="mc-st-form-item">
              <ion-label position="floating">{{$t('solidaryTransport.login.form.fields.login')}} *</ion-label>
              <ion-input class="mc-st-form-input" type="email" @input="username = $event.target.value"></ion-input>
            </ion-item>

            <ion-item class="mc-st-form-item">
              <ion-label position="floating">{{$t('solidaryTransport.login.form.fields.password')}} *</ion-label>
              <ion-input class="mc-st-form-input" type="password" @input="password = $event.target.value"></ion-input>
            </ion-item>
          </div>

          <div class="mc-form-alternate">
            <div class="pointer" @click="forgotPassword()"><u>{{$t('solidaryTransport.login.form.forgotPassword')}}</u></div>
            <br>
            <div class="pointer" @click="$router.push({name: 'solidaryTransport.register' })" v-html="$t('solidaryTransport.login.form.notRegistered')"></div>
          </div>

          <div class="mc-st-form-controls with-multiple" :class="{'is-loading': loading}">
            <ion-button class="mc-st-form-control as-back" color="light" v-html="$t('solidaryTransport.buttons.back')" @click="$router.back()"></ion-button>

            <ion-button class="mc-st-form-control as-loader" color="success" @click="loginUser()">
              <ion-icon slot="start" name="sync" size="large"></ion-icon>
              <span v-html="$t('solidaryTransport.buttons.validate')"></span>
            </ion-button>
          </div>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss"></style>

<script>
import jwt_decode from 'jwt-decode'
import { toast } from '../../Shared/Mixin/toast.mixin'
import {isPlatform} from "@ionic/core";

export default {
  name: 'solidaryTransport.login',
  mixins: [toast],
  components: {},
  props: {
    initRedirect: {
      type: Object,
      required: true
    }
  },
  watch: {
    initRedirect: function(redirect) {
      this.redirect = redirect
    }
  },
  data () {
    return {
      username: undefined,
      password: undefined,
      loading: false,
      redirect: this.initRedirect,
      alert: undefined
    }
  },
  computed: {},
  methods: {
    loginUser: function () {
      if (!this.loading) {
        this.loading = true

        let username = this.username,
            password = this.password

        this.$store.dispatch('login', { username, password })
          .then((response) => {
            this.getUser(response)
          })
          .catch(err => {
            this.loading = false
            console.log(err)
            if (err.response.status == 401) {
              this.presentToast("La connexion a échoué, veuillez vérifier votre adresse mail et votre mot de passe.", 'danger')
            } else {
              this.presentToast("Une erreur est survenue", 'danger')
            }
          })
      }
    },
    getUser(response) {
      const idUser = jwt_decode(response.data.token).id;
      this.$store.dispatch('getUser', { idUser })
        .then(res => {
          console.log("La connexion est un succès", 'success')
          this.$router.push(this.redirect)
          setTimeout(() => {
            this.loading = false
          }, 100)
        })
       .catch(err => {
          this.loading = false
          console.log("Une erreur est survenue", 'danger')
       })
    },
    forgotPassword() {
      return this.$ionic.alertController
        .create({
          header: this.$t('solidaryTransport.login.forgotPassword.title'),
          inputs: [
            {
              name: 'email',
              id: 'email',
              placeholder: this.$t('solidaryTransport.login.forgotPassword.email')
            }
          ],
          buttons: [
            {
              text: this.$t('solidaryTransport.login.forgotPassword.actions.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            },
            {
              text: this.$t('solidaryTransport.login.forgotPassword.actions.validate'),
              handler: (data) => {
                let mobileRegistration = 1;
                if (isPlatform(window.document.defaultView, "ios")) {
                  mobileRegistration = 2;
                }
                if (isPlatform(window.document.defaultView, "android")) {
                  mobileRegistration = 3;
                }
                const backLink = process.env.VUE_APP_DOMAIN + '/#/solidary-transport/reset-password?token=';

                this.$store.dispatch('resetPassword', {email: data.email, mobileRegistration : mobileRegistration, backLink: backLink}).then(() => {
                  this.presentToast(this.$t('solidaryTransport.login.forgotPassword.messages.success'), 'secondary')
                }).catch(() => {
                  this.presentToast(this.$t('solidaryTransport.login.forgotPassword.messages.error'), 'danger')
                })
              }
            }
          ]
        })
        .then((alert) => {
          alert.present()
        })
    }
  },
  created: function () {},
  beforeDestroy: function() {}
}
</script>
