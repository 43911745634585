<template>
  <div>
      <ion-item v-for="(module,index) in modules" :key="index" lines="none" class="module-item">
        <img slot="start" alt="" style="width: 34px; margin-left: 16px;" :src="'/assets/' + module.linkName + '.png'"/>
        <ion-label v-on:click="redirectModule(module.linkName)" color="dark"><b>{{ module.name }}</b></ion-label>
      </ion-item>
  </div>
</template>
<style>
.module-item:not(:last-child) {
  border-bottom: 1px lightgrey solid;
}
</style>
<script>

export default {
  name: "Modal",
  props: {
    title: { type: String, default: "Choix du module" },
    appModule: "",
    router: ""
  },
  data() {
    return {
      modules: [],
      declareVolunteer: JSON.parse(
        process.env.VUE_APP_SOLIDARY_DECLARE_VOLUNTEER
      )
    };
  },
  created() {
    this.checkModule();
  },
  methods: {
    checkModule: function() {
      const existingVueInstance = document.querySelector('#app').__vue__;
      if (this.appModule.CARPOOL) {
        this.modules.push({
          name: existingVueInstance.$t('Modules.carpool'),
          linkName: "carpoolsHome"
        });
        if (this.appModule.SOLIDARYTRANSPORT) {
          this.modules.push({
            name: this.declareVolunteer ? existingVueInstance.$t('Modules.solidary') : existingVueInstance.$t('Modules.solidary-volunteer'),
            linkName: "solidaryTransport"
          });
        }
        if (this.appModule.HITCHHIKING) {
          this.modules.push({
            name: existingVueInstance.$t('Modules.hitchHiking'),
            linkName: "hitchHiking.home"
          });
        }
      }
    },

    redirectModule(linkName) {
      this.router.push({name: linkName})
      this.$ionic.popoverController.dismiss()
    }
  }
};
</script>
