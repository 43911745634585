<template>
	<ion-vue-router />
</template>

<style lang="scss">
	@import './SolidaryTransport.style.scss';
</style>

<script>
import Color from "color";

export default {
  name: 'solidaryTransport',
  data () {
    return {}
  },
  created: function () {
    this.changeColor();
  },
  methods: {
    changeColor() {
      var colors = [];
      colors.push({name: 'primary', color: Color('#' + process.env.VUE_APP_PRIMARY)});
      colors.push({name: 'secondary', color: Color('#' + process.env.VUE_APP_SECONDARY)});
      colors.push({name: 'tertiary', color: Color('#' + process.env.VUE_APP_TERTIARY)});
      colors.push({name: 'success', color: Color('#' + process.env.VUE_APP_SUCCESS)});
      colors.push({name: 'warning', color: Color('#' + process.env.VUE_APP_WARNING)});
      colors.push({name: 'danger', color: Color('#' + process.env.VUE_APP_DANGER)});

      colors.forEach(item => {
        document.documentElement.style.setProperty('--ion-color-' + item.name, item.color.hex());
        document.documentElement.style.setProperty('--ion-color-' + item.name + '-rgb', item.color.rgb().array().toString());
        document.documentElement.style.setProperty('--ion-color-' + item.name + '-contrast', '#ffffff');
        document.documentElement.style.setProperty('--ion-color-' + item.name + '-contrast-rgb', '255, 255, 255');
        document.documentElement.style.setProperty('--ion-color-' + item.name + '-shade', item.color.lighten(0.2).hex());
        document.documentElement.style.setProperty('--ion-color-' + item.name + '-tint', item.color.darken(0.2).hex());
      })

      document.documentElement.style.setProperty('--ion-color-light-contrast', '#' + process.env.VUE_APP_PRIMARY);
      document.documentElement.style.setProperty('--ion-color-light-contrast-rgb', '#' + process.env.VUE_APP_PRIMARY_RGB);
    }
  }
}
</script>
